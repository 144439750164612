<template>
    <div class="cursor-default">
        <custom-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colour-type="buttonType">
            {{title ? title : action.title}}
        </custom-button>

      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>
        <portal to="destination">
            <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title"
                        :confirmationString='`Are you sure you want to \"${action.title}\"`'
                        @removeFromDisplay="displayModal = false" @perform="perform()" :confirm-button-text="title ? title : action.title">
                <div class="grid grid-cols-3 gap-2">
                    <div class="grid grid-cols-3 col-span-2 auto-rows-min">
                        <collapsable-header class="col-span-3" :collapsable="false" title="Invite Users">
                        </collapsable-header>
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            User Type
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('user_type')">*</span>
                        </div>
                        <div class="col-span-2 whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="user_type"
                                :show-label="false"
                                v-model="formData['user_type']">
                            </property-or-field>
                            <div v-show="errors.has('user_type')" class="text-v3-red text-sm">{{ errors.first('user_type') }}</div>
                        </div>
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            User Roles
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('user_roles')">*</span>
                        </div>
                        <div class="col-span-2 whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="user_roles"
                                :show-label="false"
                                v-model="formData['user_roles']">
                            </property-or-field>
                            <div v-show="errors.has('user_roles')" class="text-v3-red text-sm">{{ errors.first('user_roles') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Emails
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('emails')">*</span>
                        </div>
                        <div class="col-span-2 whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="emails"
                                :show-label="false"
                                v-model="formData['emails']">
                            </property-or-field>
                            <div v-show="errors.has('emails')" class="text-v3-red text-sm">{{ errors.first('emails') }}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 auto-rows-min">
                        <collapsable-header class="col-span-3" :collapsable="false" title="Employment Details">
                        </collapsable-header>

                        <div class="col-span-2 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            CSCS Card
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('cscs_card')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="cscs_card"
                                :show-label="false"
                                v-model="formData['cscs_card']">
                            </property-or-field>
                            <div v-show="errors.has('cscs_card')" class="text-v3-red text-sm">{{ errors.first('cscs_card') }}</div>
                        </div>

                        <div class="col-span-2 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Passport
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('passport')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="passport"
                                :show-label="false"
                                v-model="formData['passport']">
                            </property-or-field>
                            <div v-show="errors.has('passport')" class="text-v3-red text-sm">{{ errors.first('passport') }}</div>
                        </div>

                        <div class="col-span-2 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            NIN
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('nin')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="nin"
                                :show-label="false"
                                v-model="formData['nin']">
                            </property-or-field>
                            <div v-show="errors.has('nin')" class="text-v3-red text-sm">{{ errors.first('nin') }}</div>
                        </div>

                        <div class="col-span-2 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            License
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('license')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="license"
                                :show-label="false"
                                v-model="formData['license']">
                            </property-or-field>
                            <div v-show="errors.has('license')" class="text-v3-red text-sm">{{ errors.first('license') }}</div>
                        </div>

                        <div class="col-span-2 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Right To Work
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('right_to_work')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="right_to_work"
                                :show-label="false"
                                v-model="formData['right_to_work']">
                            </property-or-field>
                            <div v-show="errors.has('right_to_work')" class="text-v3-red text-sm">{{ errors.first('right_to_work') }}</div>
                        </div>

                        <div class="col-span-2 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Bank Details
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('bank_details')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="bank_details"
                                :show-label="false"
                                v-model="formData['bank_details']">
                            </property-or-field>
                            <div v-show="errors.has('bank_details')" class="text-v3-red text-sm">{{ errors.first('bank_details') }}</div>
                        </div>
                    </div>
                </div>

                <div v-show="errors.has('items')" class="text-v3-red text-sm">{{ errors.first('items') }}</div>

            </large-model>
        </portal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LargeModel from "@/v3/models/LargeModel.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import Header from "@/v2/components/Header.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import CustomButton from "@/v3/Buttons/CustomButton.vue";

let follow = Siren.Helper.follow;
export default {

    inject: ["$validator"],
    name: "addOnboardingUser",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            clientId: null,
            projectId: null,
            deliveryMethod: 'delivery'
        }
    },
    components: {
        CustomButton,
        CollapsableHeader,
        Header,
        BlankHeader,
        PropertyOrField,
        LargeModel,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            // if(payload['client_id'] === undefined)
            // {
            //     Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            //     Siren.get(process.env.VUE_APP_API_URL + "/api/")
            //         .then(
            //             follow("current-user")
            //         )
            //         .then(res => {
            //             this.clientId = res.body.properties.get("client_id");
            //         }, this);
            // }
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal) {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else if (error.status === 403 || error.status === 401) {
                        let errorMessage = "You do not have permission to " + this.action.title;
                        if (error.response.body.message) {
                            errorMessage = error.response.body.message;
                        }
                        alert(errorMessage);
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
        isActionRequired(fieldId){
            if (!this.action) {
                return false;
            }
            let field = this.action.fields.filter(field => field.name === fieldId).first();
            if(field){
                if(field.validation != null) {
                    return field.validation.includes('required')
                }
            }
            return false;
        },
    },
}
</script>

<style scoped>

</style>
