<template>
    <pagination title="Induction Requests" :details-open="viewUser" :entities="users" :filterAction="filterAction" :response="response">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-v3-gray-600">Status</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Role</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Phone</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Actions</th>
        </template>
        <template v-slot:items>
            <tr v-for="user in users">
                <template v-if="user.rels.contains('user')">
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-medium sm:pl-6">
                        {{user.entity.properties.get('name')}}<br>
                        <span class="text-v3-gray-600 dark:text-v3-gray-400">{{user.entity.properties.get('email')}}</span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-sm text-v3-gray-800">On Site</td>
                    <td class="whitespace-normal px-3 py-2 text-xs">
                        {{user.entity.properties.get('roles').replaceAll(',',', ')}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
                        {{user.entity.properties.get('contact_number')}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
                        <form-action v-if="user.entity.actions.get('accept-pending-user')" :action="user.entity.actions.get('accept-pending-user')" class="mb-2" :onSuccessPath="$route.path" button-type="positive">
                        </form-action>
                        <form-action v-if="user.entity.actions.get('decline-pending-user')" :action="user.entity.actions.get('decline-pending-user')" class="mb-2" :onSuccessPath="$route.path" button-type="negative">
                        </form-action>
                    </td>
    <!--                <td class="whitespace-nowrap px-3 py-2 text-sm text-v3-gray-800"></td>-->
                </template>
                <template v-else-if="user.rels.contains('onboarding')">
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-medium sm:pl-6">
                        {{user.entity.properties.get('email')}}
<!--                        {{user.entity.properties.get('name')}}<br>-->
<!--                        <span class="text-v3-gray-600 dark:text-v3-gray-400">{{user.entity.properties.get('email')}}</span>-->
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-sm text-v3-gray-800">{{user.entity.properties.get('status')}}</td>
                    <td class="whitespace-normal px-3 py-2 text-xs">
                        {{user.entity.properties.get('role')}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
<!--                        {{user.entity.properties.get('contact_number')}}-->
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
                        <form-action v-if="user.entity.actions.get('update-start-date')" :action="user.entity.actions.get('update-start-date')" class="mb-2" :onSuccessPath="$route.path">
                        </form-action>
                    </td>
                </template>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Siren from "super-siren";
import FormAction from "@/v3/components/forms/FormAction.vue";

export default {
  name: 'HrPeoplePendingPagination',
    data(){
      return {
        viewUser: false,
          userDetails: null,
          singleUserDetailsOpen: false
      }
    },
    components: {FormAction, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.userDetails && this.userDetails.properties['name']) {
                return this.userDetails.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
  props: {
    users: {},
      response: {
        type: Object,
          default: null
      },
      page:{
        type: String,
          default: 'hr-people'
      }
  },
    methods:{
      openUserDetails(userEntity){
          this.$emit('setLoading');
          this.userDetails = false;
          this.viewUser = true;
          this.getUserDetails(userEntity);
      },
        getUserDetails(userEntity){
            let selfLink = userEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setUserDetails', response.body);
            }).catch(function(){

            });
        },
        closeDetails(){
          this.userDetails = false;
          this.viewUser = false;
        }
    }
}
</script>
